import React, { useEffect, useState } from 'react';
import { Container, Nav, Navbar, Dropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { postToAPI,getFromAPI } from '../Utils/utils.jsx'; // Import utility functions

// img
import Logo from '../../images/logo.png';
import LogoSm from '../../images/logo-sm.png';

export default function TopMenu() {
    const location = useLocation();
    const storeduserid = localStorage.getItem('userId');
    const [userName, setUserName] = useState('');
    const [storedUserType, setStoredUserType] = useState('');
  
    useEffect(() => {
      const fetchData = async () => {
        // Retrieve userType from localStorage
        const userType = localStorage.getItem('userType');
        console.log("UserType: ", userType)
        setStoredUserType(userType);
  
        // Ensure userType is a string for comparison
        if (userType === "1") {
          try {
            const storedUserId = localStorage.getItem('userId'); // Retrieve stored userId
            const fetchDrivrData = await getFromAPI(`driver/?id=${storedUserId}`);
            if(fetchDrivrData.status){
            const fullName = fetchDrivrData.drivers[0].FirstName+" "+fetchDrivrData.drivers[0].LastName;
            setUserName(fullName);
            }
          } catch (error) {
            console.error('Error fetching citizen data:', error);
          }
        } else if (userType === "0") {
          try {
            const storedUserId = localStorage.getItem('userId'); // Retrieve stored userId
            const fetchSupervisorData = await getFromAPI(`Supervisor/?id=${storedUserId}`);
            const fullName = fetchSupervisorData[0].FirstName+" "+fetchSupervisorData[0].LastName;
            setUserName(fullName);
            
          } catch (error) {
            console.error('Error fetching supervisor data:', error);
          }
        }
      };
  
      fetchData(); // Call the async function
    }, []); // Empty dependency array means this effect runs once on mount

    return (
        <>
            <Navbar expand="lg" className="top-menu">
                <Container>
                    <div className='leftbar-logo'>
                        <Link to='/dashboard' >
                            <img src={Logo} alt="" className='d-sm-block d-none' height={32} />
                            <img src={LogoSm} alt="" className='d-sm-none d-block' height={32} />
                        </Link>
                    </div>
                    <div className='topbar-user d-flex align-items-center d-lg-none ms-auto'>
                        <Link to="" className='nav-link me-3'>
                            <i className="bi bi-bell-fill fs-24"></i>
                        </Link>
                        <Dropdown>
                            <Dropdown.Toggle variant="p-0" id="top-user-sm">
                                <div className='d-flex align-items-center'>
                                    <i className="bi bi-person-fill fs-26"></i>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end" title="Dropdown end">
                                <Link to='/' className='dropdown-item'><i className="bi bi-box-arrow-right fs-18 me-2"></i> Logout</Link>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <Navbar.Toggle className='ms-3' aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mx-auto">
                            <Link to='/dashboard' className={`nav-link ${location.pathname === '/dashboard' ? 'active' : ''}`}>Dashboard</Link>
                            <Link to='/mapping_assignment' className={`nav-link ${location.pathname === '/mapping_assignment' ? 'active' : ''}`}>Mapping & Assignment</Link>
                            <Link to='/management' className={`nav-link ${location.pathname === '/management' ? 'active' : ''}`}>Management</Link>
                        </Nav>
                        <Nav className="top-right-navbar topbar-user topbar-user d-none d-lg-flex align-items-center">
                            <Link to="" className='nav-link'>
                                <i className="bi bi-bell-fill"></i>
                            </Link>
                            <Dropdown>
                                <Dropdown.Toggle variant='' className="p-0" id="top-user-lg">
                                    <Link to="" className='nav-link d-flex align-items-center'>
                                        <i className="bi bi-person-fill fs-26"></i> <h5 className='fs-20 d-none d-xl-block mb-0'><span className='ms-2'>{userName}</span></h5>
                                    </Link>
                                </Dropdown.Toggle>
                                <Dropdown.Menu align="end" title="">
                                    <Link to='/' className='dropdown-item'><i className="bi bi-box-arrow-right fs-18 me-2"></i> Logout</Link>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}
