import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap';
import JSZip from "jszip";
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import 'datatables.net-dt/css/jquery.dataTables.css';
import VehicleDetailsData from './dashboard_data.json';

export default function VehicleDetails() {
    const [vehicleDetailsData] = useState(VehicleDetailsData);
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable("#vehicledetailsdatatable")) {
            $("#vehicledetailsdatatable").DataTable({
              dom: 'Bfrtip',
              buttons: [
                {
                  extend: 'excel',
                  className: 'btn btn-export btn-md btn-lightdark',
                  text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel'
                },
                {
                  extend: 'pdf',
                  className: 'btn btn-export btn-md btn-lightdark',
                  text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to PDF'
                },
                {
                  extend: 'print',
                  className: 'btn btn-export btn-md btn-lightdark',
                  text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M19 8H5C3.9 8 3 8.9 3 10V16H7V20H17V16H21V10C21 8.9 20.1 8 19 8M17 18H7V14H17V18M19 12H5V10H19V12Z" style="fill: currentcolor;"></path></svg> Print'
                }
              ],
            //   data: dataWithCollection.filter(item => {
            //     if (selectedFilter === 'All') return true; // No filter applied
            //     // Filter based on selectedFilter criteria
            //     return item[5] === selectedFilter; // Example: filter by statusText
            //   }),           
               columns: [
                { title: "No." },
                { title: "Assigned Date" },
                { title: "Ward" },
                { title : "Zone"},
                { title: "Destination" },
                {
                  title: "Status",
                  render: function (data, type, row) {
                    // const statusText = row[5];
                    // const statusBar = row[6];
                    return `
                      <span class="badge badge-soft-success">Done</span>
                    `;
                  }
                },
              ],
              autoWidth: false,
              columnDefs: [
                {
                  targets: -1,
                  orderable: false
                }
              ],
              language: {
                search: "",
                searchPlaceholder: "Search...",
                paginate: {
                  previous: "«",
                  next: "»"
                }
              }
            });
          }
    }, []);

  return (
    <>
      <Table responsive bordered className="mb-0 table-nowrap" id="vehicledetailsdatatable">
        <thead className="table-light"></thead>
        <tbody>
            {vehicleDetailsData.vehicleDetailsDataList.map((data, i) =>
            <tr key={i}>
                <td>{data.vdNo}</td>
                <td>{data.vdDate}</td>
                <td>{data.vdWard}</td>
                <td>{data.vdZone}</td>
                <td>{data.vdDestination}</td>
                <td><span className="badge badge-soft-success">Done</span></td>
            </tr>
            )}
        </tbody>
      </Table>
    </>
  )
}
