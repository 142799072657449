import React, { useEffect, useState } from 'react';
import { Button, Card, Table, Modal, Row, Col, Form } from "react-bootstrap";
import JSZip from "jszip";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import DatePicker from 'react-datepicker';
import { deleteFromAPI, getFromAPI, postToAPI, putToAPI } from '../Utils/utils';
import { toast } from 'react-toastify';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

export default function VehicleAssignment({activeTab}) {
    // Modal state
    const [showVehicleAssignmentModal, setshowVehicleAssignmentModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [showDeleteModal, setshowDeleteModal] = useState(false);
    const [VehicleAssignmentData, setVehicleAssignmentData] = useState();
    // Date picker state
    const [startDate, setStartDate] = useState(new Date()); // For date picker
    const [VehicleData, setVehicleData] = useState();
    const [DriversData, setDriversData] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [EditVehicleAssignID, setEditVehicleAssignID] = useState();
    const [DeleteVehicleAssignID, setDeleteVehicleAssignID] = useState();
    const [validated, setValidated] = useState(false);

    const handleDateFormat = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const InitialVehicleAssignmentInputData = {
        name: "",
        vehicleId: 0,
        driverId: 0,
        isActive: true,
        date_assigned: handleDateFormat(new Date()),
    }
    const [VehicleAssignmentInputData, setVehicleAssignmentInputData] = useState(InitialVehicleAssignmentInputData);

    const fetchVehicleAssignmentData = () => {
        getFromAPI('VehicleAssignment/').then(data => {
            const sortedVAData = data.sort((a, b) => {
                return new Date(b.date_assigned) - new Date(a.date_assigned);
            });
            setVehicleAssignmentData(sortedVAData);
        }).catch(error => {
            console.error('Error fetching Zone data:', error);
        });
    }

    const fetchVehicleData = async () => {
            getFromAPI(`vehicle/`).then(data => {
                if (data) {
                    setVehicleData(data);
                } else {
                    console.error('Unexpected data structure:', data);
                }
            }).catch(error => {
                console.error('Error fetching user data:', error);
            });
    }

    const fetchDriversData = async () => {
            getFromAPI(`driver/`).then(data => {
                if (data) {
                    setDriversData(data.drivers);
                } else {
                    console.error('Unexpected data structure:', data);
                }
            }).catch(error => {
                console.error('Error fetching user data:', error);
            });
    }

    const handleShowEditVehicleAssignment = (title, id) => {
        setIsEdit(true);
        setEditVehicleAssignID(id);
        getFromAPI(`VehicleAssignment/?id=${id}`).then(dataArr => {
            let data = dataArr[0];
            setVehicleAssignmentInputData({...VehicleAssignmentInputData, name:data.name, vehicleId:data.vehicleId, driverId:data.driverId, isActive:data.isActive, date_assigned:data.date_assigned});
        });
        handleshowVehicleAssignmentModal(title);
    };

    const handleshowVehicleAssignmentModal = (title) => {
        setModalTitle(title);
        setshowVehicleAssignmentModal(true);
    };

    const handleCloseModal = () => {
        setshowVehicleAssignmentModal(false);
        UpdateVehicleAssignDataTable();
        setIsEdit(false);
        setValidated(false);
        setshowDeleteModal(false);
        setDeleteVehicleAssignID(null);
        setEditVehicleAssignID(null);
        setVehicleAssignmentInputData(InitialVehicleAssignmentInputData);
    };

    const handleDeleteData = (id) => {
        setDeleteVehicleAssignID(id);
        setshowDeleteModal(true);
    };

    const handleConfirmDelete = async() => {
        try {
            const destinationMapData = await getFromAPI(`DestinationMapping/?vehicle_assignment_id=${DeleteVehicleAssignID}`);
            const destinationAssignData = await getFromAPI(`DestinationAssignment/?vehicle_assignment_id=${DeleteVehicleAssignID}`);
            if(destinationMapData.length === 0 && destinationAssignData.length === 0) {
                const Response = await deleteFromAPI(`VehicleAssignment/${DeleteVehicleAssignID}/`);
                if (Response.status) {
                    toast.success('Vehicle assignment data Successfully deleted');
                    handleCloseModal();
                } else {
                    toast.error('Failed to delete vehicle assignment data');
                }
            } else {
                toast.error('Selected vehicle assignment is assigned to a destination. Make sure that vehicle assignment is not assigned before delete.');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An unexpected error occurred');
        }
    };

    const handleAddVehicleAssignment = async (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
        } else {
            e.preventDefault(); // Prevent page reload
            setValidated(true);

            if(VehicleAssignmentInputData.name.trim() === "") {
                toast.error("Enter valid vehicle assignment name");
                return
            }
            if(isEdit) {
                try {
                    const Response = await putToAPI(`VehicleAssignment/${EditVehicleAssignID}/`, VehicleAssignmentInputData);
                    if (Response.status) {
                        toast.success('Vehicle Assignment data Successfully updated');
                        handleCloseModal();
                    } else {
                        toast.error('Failed to update Vehicle Assignment data');
                    }
                } catch (error) {
                    console.error('Error:', error);
                    toast.error('An unexpected error occurred');
                }
            } else {
                const isDuplicateName = VehicleAssignmentData.some(VehicleAssignment => {
                    return (
                        VehicleAssignment.name.toLowerCase() === VehicleAssignmentInputData.name.toLowerCase() &&
                        (!isEdit || VehicleAssignment.id !== EditVehicleAssignID)
                    );
                });
    
                if (isDuplicateName) {
                    toast.error('Vehicle assignment name already exists');
                    return;
                }

                try {
                    const Response = await postToAPI("VehicleAssignment/", VehicleAssignmentInputData);
                    if (Response.status) {
                        toast.success('Vehicle Assignment data Added Successfully');
                        handleCloseModal();
                    } else {
                        toast.error('Failed to add Vehicle Assignment data');
                    }
                } catch (error) {
                    console.error('Error:', error);
                    toast.error('An unexpected error occurred');
                }
            }
        }
    }

    const getDriverNameByID = (id) => {
        const d = DriversData.find(driver => driver.id === id);
        return d.FirstName + " " + d.LastName;
    }

    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (VehicleAssignmentData && VehicleData && DriversData && !$.fn.dataTable.isDataTable("#VehicleAssignmentDataTable")) {
            $("#VehicleAssignmentDataTable").DataTable({
                dom: 'Bflrtip',
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel',
                        exportOptions: {
                            columns: ':not(:last-child)' 
                        }
                    },
                    {
                        extend: 'pdf',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf',
                        exportOptions: {
                            columns: ':not(:last-child)' 
                        }
                    },
                    {
                        extend: 'print',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M19 8H5C3.9 8 3 8.9 3 10V16H7V20H17V16H21V10C21 8.9 20.1 8 19 8M17 18H7V14H17V18M19 12H5V10H19V12Z" style="fill: currentcolor;"></path></svg> Print',
                        exportOptions: {
                            columns: ':not(:last-child)' 
                        }
                    }
                ],
                data: VehicleAssignmentData.map((data, i) => [
                    i + 1,
                    data.name,
                    VehicleData.find(vehicle => vehicle.id === data.vehicleId).Name,
                    getDriverNameByID(data.driverId),
                    data.date_assigned,
                    data.isActive,
                    data.id
                ]),
                columns: [
                    { title: "No." },
                    { title: "Assignment Name" },
                    { title: "Vehicle" },
                    { title: "Driver Name" },
                    { title: "Date" },
                    {
                        title: "Status",
                        render: function (data, type, row) {
                            let statusColor = row[5] ? 'success' : 'danger';
                            let statusText = row[5] ? 'Active' : 'in-Active';
                            return `
                            <span class="badge badge-soft-${statusColor}">${statusText}</span>
                          `;
                        }
                    },
                    {
                        title: "Action",
                        render: function (data, type, row) {
                            return `
                                <div class="d-flex">
                                    <button class="btn btn-soft-primary table-btn me-2"><i class="bi bi-pencil-square edit-vehicle-Assignment" data-va-id="${row[6]}"></i></button>
                                    <button class="btn btn-soft-danger table-btn"><i class="bi bi-trash3 delete-vehicle-Assignment" data-va-id="${row[6]}"></i></button>
                                </div>
                            `;
                        }
                    },
                ],
                autoWidth: false,
                columnDefs: [
                    {
                        targets: -1,
                        orderable: false,
                    },
                ],
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: "«",
                        next: "»",
                    },
                },
            });

            document.querySelector('#VehicleAssignmentDataTable tbody').addEventListener('click', function (event) {
                if (event.target && event.target.classList.contains('edit-vehicle-Assignment')) {
                    const VehicleAssignment_id = event.target.getAttribute('data-va-id');
                    handleShowEditVehicleAssignment("Edit Vehicle Assignment", VehicleAssignment_id);
                }
                if (event.target && event.target.classList.contains('delete-vehicle-Assignment')) {
                    const VehicleAssignment_id = event.target.getAttribute('data-va-id');
                    handleDeleteData(VehicleAssignment_id);
                }
            });

        }
    }, [VehicleAssignmentData, VehicleData, DriversData]);

    const UpdateVehicleAssignDataTable = () => {
        const PODT = $('#VehicleAssignmentDataTable').DataTable();
        getFromAPI("VehicleAssignment/")
            .then(response => {
                const sortedVAData = response.sort((a, b) => {
                    return new Date(b.date_assigned) - new Date(a.date_assigned);
                });
                setVehicleAssignmentData(sortedVAData);
                let DTData = sortedVAData.map((data, i) => [
                    i + 1,
                    data.name,
                    VehicleData.find(vehicle => vehicle.id === data.vehicleId).Name,
                    getDriverNameByID(data.driverId),
                    data.date_assigned,
                    data.isActive,
                    data.id
                ]);
            PODT.clear().rows.add(DTData).draw();
            })
            .catch(error => {
            console.error('Error:', error);
            });
    }

    useEffect(()=>{
        fetchVehicleAssignmentData();
        fetchVehicleData();
        fetchDriversData();
    }, []);

    useEffect(()=>{
        fetchVehicleAssignmentData();
        fetchVehicleData();
        fetchDriversData();
    }, [activeTab]);

    return (
        <>
            <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between my-3">
                <Card.Title className="mb-0">Vehicle Assignment</Card.Title>
                <Button variant="primary" onClick={() => handleshowVehicleAssignmentModal("Add Vehicle Assignment")}>
                    <i className="bi bi-plus-square me-2"></i> Add Vehicle Assignment
                </Button>
            </div>

            <Table responsive bordered className="mb-0 table-nowrap" id="VehicleAssignmentDataTable">
                <thead className="table-light"></thead>
            </Table>

            {/* Vehicle Modal */}
            <Modal centered size="lg" show={showVehicleAssignmentModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={(e) => handleAddVehicleAssignment(e)}>
                    <Modal.Body className="pb-0">
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="form-space">
                                    <Form.Label>Assignment Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Assignment Name..." name="vehiclename" value={VehicleAssignmentInputData.name} onChange={(e)=>setVehicleAssignmentInputData({...VehicleAssignmentInputData, name:e.target.value})} required isInvalid={validated && !VehicleAssignmentInputData.name.trim()} />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter vehicle assignment name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="form-space">
                                    <Form.Label>Vehicle ID</Form.Label>
                                    <Form.Select as="select" name="VehicleType" value={VehicleAssignmentInputData.vehicleId} onChange={(e)=>setVehicleAssignmentInputData({...VehicleAssignmentInputData, vehicleId:e.target.value})} required isInvalid={validated && (VehicleAssignmentInputData.vehicleId === 0)} >
                                        <option value="" >Select Vehicle</option>
                                        {VehicleData && VehicleData.map((vehicle) => (
                                            <option key={vehicle.id} value={vehicle.id}>
                                                {vehicle.Name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select vehicle.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="form-space">
                                    <Form.Label>Driver ID</Form.Label>
                                    <Form.Select as="select" name="DriverName" value={VehicleAssignmentInputData.driverId} onChange={(e)=>setVehicleAssignmentInputData({...VehicleAssignmentInputData, driverId:e.target.value})} required isInvalid={validated && (VehicleAssignmentInputData.driverId === 0)} >
                                        <option value="" >Select Driver</option>
                                        {DriversData && DriversData.map((driver) => (
                                            <option key={driver.id} value={driver.id}>
                                                {driver.FirstName + " " + driver.LastName}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select driver.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="form-space">
                                    <Form.Label>Date</Form.Label>
                                    <div className='input-icon-content'>
                                        <DatePicker value={VehicleAssignmentInputData.date_assigned} onChange={(date) => setVehicleAssignmentInputData({...VehicleAssignmentInputData, date_assigned:handleDateFormat(date)})} dateFormat="yyyy-MM-dd" showIcon />
                                        <i className="bi bi-calendar2-event input-icon"></i>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Check
                                    type="checkbox"
                                    id="default-checkbox"
                                    label="Is Active"
                                    checked={VehicleAssignmentInputData.isActive}
                                    onChange={(e)=>setVehicleAssignmentInputData({...VehicleAssignmentInputData, isActive:e.target.checked})}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="mt-1">
                        <Button variant="primary" type='submit'>Save</Button>
                        <Button variant="light" onClick={handleCloseModal}>Close</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/* Delete Modal */}
            <Modal centered show={showDeleteModal} onHide={() => setshowDeleteModal(false)}>
                <Modal.Body className="text-center">
                    <div className="avatar avatar-xxxl bg-border-soft-danger rounded-circle text-danger mx-auto ">
                        <i className="bi bi-trash"></i>
                    </div>
                    <h4 className="fw-semibold mt-4">Are you sure?</h4>
                    <p className="text-muted fs-18">Do you really want to delete this record?</p>
                    <div className="d-flex gap-3">
                        <Button variant="light w-50" onClick={() => setshowDeleteModal(false)}>Cancel</Button>
                        <Button variant="danger w-50" onClick={handleConfirmDelete}>Delete</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
